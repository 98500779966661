<template>
  <main>
    <section
      class="min-vh-100 d-flex align-items-center justify-content-center"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-12 text-center text-dark d-flex align-items-center justify-content-center"
          >
            <div>
              <a href="/"
                ><img
                  class="img-fluid"
                  src="/assets/img/404.png"
                  alt="girl illustration"
              /></a>
              <h1 class="mt-4 mb-4">Page Not Found</h1>
              <a class="btn btn-primary animate-hover" href="/"
                ><span class="fas fa-chevron-left animate-left-3"></span> Go
                back home</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
